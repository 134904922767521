/* eslint-disable no-console */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { BACKEND_URL } from '@/constants';
import { FETCH_METHODS } from '@/types';

export async function auth(
  body: string | undefined = '',
  invite: string | null,
) {
  const inviteCode = invite ? `&invite_code=${invite}` : '';

  try {
    const fetchUrl = `${BACKEND_URL}/auth?data=${encodeURIComponent(
      body,
    )}${inviteCode}`;

    const options: AxiosRequestConfig = {
      method: FETCH_METHODS.POST,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios(fetchUrl, options);

    if (response?.data?.access_token) {
      localStorage.setItem('botai_access_token', response.data.access_token);
    }

    return response;
  } catch (error) {
    console.error('An error occurred while authorization');
    if (error instanceof AxiosError) {
      return error.response;
    }
  }
}
